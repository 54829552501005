.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  &.noPadding {
    padding: 0;
  }

  &.vertical {
    flex-direction: column;
  }
}
