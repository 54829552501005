@import '@crystal-eyes/constants.scss';

.container {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 24px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 400px) {
    width: 360px;
  }

  @media (min-width: 600px) {
    width: 617px;
  }

  .heading {
    margin: 8px auto;
    font-size: 14px;
    font-style: italic;
    color: $gray-text;
    font-family: var(--font-lato);
    font-weight: 300;
  }

  .member-wrapper {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: stretch;
    margin: 0 auto 15px;

    @media (max-width: $mobile) {
      flex-direction: column;
    }

    .member {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: stretch;
      margin: 0 20px;

      @media (max-width: $mobile) {
        margin: 10px 0;
      }

      .inner {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: stretch;

        .photo {
          margin-top: 7px;
        }

        .profile-wrapper {
          margin-left: 10px;
          order: 0;
          flex: 1 1 auto;
          align-self: auto;
          line-height: 1;

          .name {
            margin: 0;
            padding: 0;
            font-size: 14px;
            display: block;
            font-family: var(--font-lato);
            font-weight: 400;
            color: $crystal-midnight-blue;
          }

          .job-title {
            margin: 0;
            padding: 0;
            display: block;
            font-size: 14px;
            font-family: var(--font-lato);
            font-weight: 300;
            color: $crystal-midnight-blue;
            font-style: italic;
            color: $gray-text;
          }
        }
      }
    }
  }
}
