@import '@crystal-eyes/constants.scss';

.container {
  margin-bottom: 128px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 60px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr !important;
    grid-gap: 0;
  }
  &.smallMargin {
    margin-bottom: 64px;
  }
  &.noGap {
    grid-gap: 0;
  }

  &.bigImage {
    grid-template-columns: 3fr 2fr;
    &.reverse {
      grid-template-columns: 2fr 3fr;
    }
  }
}

.hide-large {
  @media (min-width: 768px) {
    display: none;
  }
}

.hide-small {
  @media (max-width: 767px) {
    display: none;
  }

  &.fixedHeight {
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px) {
      display: none;
    }
  }

  &.tallImage {
    display: flex;
    justify-content: center;
  }
}

.image {
  width: 100%;
  border-radius: 4px;

  filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.1));

  &.noImageShadow {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  &.fixedHeight {
    object-fit: contain;
    height: 100%;
    max-height: 350px;
    @media (max-width: 767px) {
      max-height: 500px;
    }
  }

  &.tallImage {
    height: 350px;
    width: unset;
  }
}

.reverse {
  @media (min-width: 766px) {
    .subtitle {
      text-align: right;
    }
    .title {
      text-align: right;
    }
    .body {
      text-align: right;
    }
  }
}

.subtitle {
  font-size: 12px;
  font-family: var(--font-lato);
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $blue;
  margin: 0 0 8px 0;
  @media (max-width: 767px) {
    text-align: center;
  }
}

.title {
  font-size: 28px;
  font-family: var(--font-lato);
  font-weight: 400;
  margin: 0 0 16px;
  color: $material-87;
  line-height: 34px;
  &.lighterTitle {
    font-size: 22px;
    font-family: var(--font-lato);
    font-weight: 700;
  }
  @media (max-width: 767px) {
    font-size: 32px;
    text-align: center;
  }
}

.body {
  font-size: 18px;
  font-family: var(--font-lato);
  font-weight: 400;
  line-height: 1.6em;
  color: $material-54;
  margin-top: 16px;
  @media (max-width: 767px) {
    text-align: center;
  }
}

.center {
  text-align: center;
}

.button {
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-content: center;
  }
}

.buttons {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  &.right {
    @media (min-width: 766px) {
      justify-content: flex-end;
      margin-right: -8px;
    }
  }
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-content: center;
  }
}

.link {
  margin-left: 8px;

  &:hover {
    text-decoration: underline;
  }
}

.line {
  height: 1px;
  background: $blue;
  position: relative;
  margin-left: -55px;
  z-index: 1000;
  width: calc(100% + 55px);
  &.reverse {
    margin-left: 0;
    &:after {
      left: auto;
      right: -9px;
    }
    &:before {
      left: 0;
      right: auto;
    }
  }

  &:after {
    position: absolute;
    content: '';
    left: -9px;
    top: -4px;
    height: 9px;
    width: 9px;
    border: 1px solid $blue;
    border-radius: 50%;
    background: #fff;
  }

  &:before {
    position: absolute;
    content: '';
    right: 0;
    top: -1px;
    height: 3px;
    width: 3px;
    border: 1px solid $blue;
    border-radius: 50%;
    background: $blue;
  }
  @media (max-width: 767px) {
    display: none;
  }
}
