@import '@crystal-eyes/constants.scss';

.container {
  display: flex;
  margin-bottom: 8px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  &.short {
    margin-bottom: 0;
  }
}
