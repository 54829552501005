@import '@crystal-eyes/constants.scss';

.wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $material-34;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 300ms ease;
}

.container {
  max-width: 780px;
  max-height: 300px;
  background: #fff;
  border-radius: 3px;
  padding: 48px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
}

.title {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 34px;
  line-height: 1.2em;
  font-family: var(--font-lato);
  font-weight: 300;
}

.description {
  margin-top: 8px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
}

.close-icon {
  position: absolute;
  height: 24px;
  width: 24px;
  top: 16px;
  right: 16px;
}
