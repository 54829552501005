@import '@crystal-eyes/constants.scss';

.container {
  margin: 64px 0;

  @media (max-height: 800px) {
    margin: auto;
    margin-bottom: 64px;
  }

  @media (max-width: $mobile) {
    margin: 0;
  }
}

.top {
  height: 700px;
  position: relative;
  display: block;
  padding: 48px 40px;
  text-align: left;
  width: 100%;
  margin: 0 auto 0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);

  .heading {
    margin: 0 0 15px;
    font-size: 24px;
    text-align: center;
    color: $gray-text;
    font-family: var(--font-lato);
    font-weight: 400;
  }

  &.spaced {
    margin-bottom: 32px;
  }

  @media (max-width: $mobile) {
    padding-top: 20px;
    height: fit-content;
  }
}

.bottom {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &.margin-top {
    margin-top: 16px;
  }
}

.market-text {
  color: $material-54;
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
}

.tiles {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.title {
  color: $material-87;
  margin: 16px auto 32px;
  text-align: center;
  font-size: 32px;
  font-family: var(--font-lato);
  font-weight: 400;
  line-height: 36px;
}

.subtitle {
  font-size: 16px;
  color: $material-54;
  text-align: center;
}

.public-assessment-title {
  font-size: 30px;
}

.button {
  background-color: $blue;
  font-family: var(--font-lato);
  font-weight: 700;
  color: $white;
  letter-spacing: 2px;
  font-size: 14px;
  text-transform: uppercase;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  &.loading {
    background-color: $material-12;
    cursor: not-allowed;
  }
  &:hover,
  &:focus {
    outline: none;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.15);
    filter: brightness(108%);
    text-decoration: none;
  }
}

.error-message {
  color: $red;
}

.gender-wrapper {
  flex: 1;
}

.autosuggest-gender-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  flex: 1;
  &.spaced {
    @media (min-width: 600px) {
      margin-right: 16px;
    }
  }
  @media (max-width: 500px) {
    flex-flow: row wrap;
  }

  .company-group {
    position: relative;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.2s;

    .company-label {
      align-items: stretch;
      margin-bottom: 5px;
    }
  }

  .dropIn {
    animation: dropIn 0.5s;
    transition: all 0.2s;
    max-height: 80px;
    overflow: visible;
  }

  @keyframes dropIn {
    0% {
      max-height: 0px;
      overflow: hidden;
    }
    100% {
      max-height: 80px;
      overflow: visible;
    }
  }

  .gender-input-group {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 25px;
    width: 100%;

    @media (max-width: 500px) {
      margin-left: 0px;
    }

    label {
      align-items: stretch;
    }

    .gender-select-box {
      align-items: stretch;
      border: none;
    }
  }
}

.hidden {
  display: none;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  text-align: left;

  @media (max-width: 500px) {
    flex-flow: row wrap;
  }
}

.hidden {
  display: none;
}

.error-label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.danger {
  color: $red;
  margin: 0;
}

.googleButton {
  margin: 0 auto 20px;
}

.permissions {
  font-size: 18px;
  color: $material-87;
  font-style: italic;
  margin: 16px 16px 0 16px;
}

.api-customer-invite-text {
  margin: 40px auto;
  max-width: 750px;
  padding: 0 16px;
  color: $material-54;
  font-family: var(--font-lato);
  font-weight: 300;
  line-height: 1.2em;
  font-size: 34px;
  text-align: center;
}

.marketing-content-wrapper {
  margin-top: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marketing-content {
  max-width: 1080px;
  padding: 0 16px;
}

.companies {
  display: flex;
  justify-content: space-around;
  align-items: center;
  filter: grayscale(1);
  transition: filter 200ms ease-in-out;
  padding: 8px 0;
  background: #fff;
  height: 104px;
  margin-top: calc(-104px / 2);
  border-radius: 4px;
  margin-bottom: 112px;
  box-shadow: 0 6px 18px 2px rgba(0, 0, 0, 0.05);
  .subtext {
    position: absolute;
    left: 0px;
    top: -40px;
    right: 0px;
    color: rgba(0, 0, 0, 0.38);
    font-size: 12px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  &:hover {
    filter: grayscale(0);
    img {
      opacity: 1;
    }
  }
  @media (max-width: $mobile) {
    filter: grayscale(0);
    flex-direction: column;
    height: auto;
  }
  img {
    opacity: 0.5;
    transition: opacity 200ms ease-in-out;
    margin: 12px 0;
    max-height: 40px;
    max-width: 150px;
  }
}
.link {
  margin-top: 16px;
  font-size: 14px;
  color: $material-34;

  a {
    text-decoration: underline;
  }

  @media (max-width: $mobile) {
    display: none;
    visibility: hidden;
  }
}
.linktext {
  font-family: var(--font-lato);
  font-weight: 300;
  font-style: italic;
}

.link-forgot-color {
  color: $action-blue;
}

.or {
  text-transform: uppercase;
  color: $material-34;
  margin-bottom: 16px;

  @media (max-width: $mobile) {
    margin: 0 0 15px;
  }
}

.socialSignInContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.registerForm {
  background-color: white;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

// Two-column Redesign
.twocol-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;

  @media (max-width: $mobile) {
    flex-direction: column;
  }
}

.auth__title {
  color: $crystal-midnight-blue;
  margin: 0 auto 8px;
  text-align: center;
  font-size: 24px;
  font-family: var(--font-lato);
  font-weight: 400;
  line-height: 28px;

  @media (max-width: $mobile) {
    font-size: 18px;
  }
}

.auth__subtitle {
  color: $crystal-midnight-blue;
  font-size: 14px;
  font-family: var(--font-lato);
  font-weight: 400;
  text-align: center;
  margin: 0 0 25px;

  @media (max-width: $mobile) {
    font-size: 14px;
    font-family: var(--font-lato);
    font-weight: 300;
    font-style: italic;
  }
}

.column {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  max-height: 695px;

  @media (max-width: $mobile) {
    width: 100%;
    max-height: unset;
    height: auto;
  }
}

.left {
  min-width: 507px;
  position: relative;
  height: 695px;

  @media (max-width: $mobile) {
    display: none;
    visibility: hidden;
  }
}

.divider {
  min-width: 1px;
  margin: 0 58px;
  background: #c4c4c4;
  height: 489px;

  @media (max-width: $mobile) {
    display: none;
    visibility: hidden;
  }
}

.right {
  min-width: 565px;
  min-height: 695px;

  @media (max-width: $mobile) {
    min-width: unset;
  }
}

.form-container {
  width: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.form-wrapper {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 0 16px 0 rgb(0, 0, 0, 0.25);
  min-height: 695px;
  padding: 40px 60px 20px;
}

.link-bottom {
  font-style: italic;
  font-family: var(--font-lato);
  font-weight: 300;
  color: $gray-text;
}

.underline {
  text-decoration: underline;
  font-style: normal;
  font-family: var(--font-lato);
  font-weight: 400;
}

.removeshadow {
  box-shadow: none !important;
}

.checkbox {
  display: flex;
  align-items: center;
  margin: 18px 0 10px;

  .input {
    padding-right: 8px;
  }

  a {
    text-decoration: underline;
  }

  span {
    font-family: var(--font-lato);
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}

.left-header {
  color: $blue;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 24px;
}

.recaptcha-error {
  color: $red;
  font-size: 14px;
  font-style: italic;
  text-align: center;
  font-family: var(--font-lato);
  font-weight: 300;
}
