@import '@crystal-eyes/constants.scss';

.wrapper {
  width: 100%;
  position: relative;
  padding: 32px 0;

  &.center-small {
    @media (max-width: 800px) {
      text-align: center;
      padding: 24px 0;
      .title {
        margin-right: 0;
      }
    }
  }
  &.noMobilePadding {
    @media (max-width: 800px) {
      padding: 0;
    }
  }
}

.top {
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  &.center {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    .title,
    .subtitle {
      margin-right: 0;
    }
  }
}

.img {
  width: 80px;
  height: 80px;
  margin-right: 24px;
  margin-top: 14px;
  object-fit: contain;
}

.titles {
  display: block;
  flex: 1;
}

.title {
  font-size: 32px;
  font-family: var(--font-lato);
  font-weight: 400;
  margin: 16px 0 8px;
  line-height: 36px;
  color: $material-87;
  @media (min-width: 600px) {
    margin-right: 150px;
    margin: 0 0 8px;
  }
}

.time {
  padding-left: 12px;
  font-size: 65%;
  color: #777777;
  font-family: var(--font-lato);
  font-weight: 400;
}

.subtitle {
  font-size: 18px;
  line-height: 1.6em;
  font-family: var(--font-lato);
  font-weight: 400;
  @media (min-width: 600px) {
    margin-right: 12px;
  }
  margin-top: 8px;
  margin-bottom: 0px;
  color: $material-54;
}

.button {
  margin-top: 20px;
}

.add-people-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
  background-color: rgba($blue, 0.12);
  box-shadow: inset 0 -12px 12px 0 rgba(0, 0, 0, 0.03);

  @media (max-width: 750px) {
    height: 100%;
    width: auto;
  }
}

.add-people-background-img {
  width: 100%;
  position: absolute;
  top: -50%;
}
