@import '@crystal-eyes/constants.scss';

.container {
  display: flex;
  margin-bottom: 25px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  &.short {
    margin-bottom: 0;
  }

  .heading {
    color: $blue;
    margin: 24px auto 0;
    font-size: 32px;
    font-family: var(--font-lato);
    font-weight: 400;
    text-align: center;
  }

  .subheading {
    color: $gray-text;
    margin: 16px auto;
    font-size: 18px;
    font-family: var(--font-lato);
    font-weight: 400;
    text-align: center;
  }
}

.top {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  margin: 24px auto 0;
  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 0 16px 0 $material-24;

  &.spaced {
    margin-bottom: 32px;
  }
}
